@each $color, $value in $user-colors {
    .user-#{$color} {
        color: $value;
        background-color: rgba($value, .3);
    }
    .profile-#{$color} {
        color: shade-color($value, 40%);
        background-color: tint-color($value, 60%);
    }
}
