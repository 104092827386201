.circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: $gray-400;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $gray-300;
    border-radius: 50%;
}

.circle-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-xs * .8;
    line-height: $font-size-xs * .8;
    background-color: $white;

    &[data-text]::before {
        content: attr(data-text);
    }
}
