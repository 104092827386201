@import "bootstrap/scss/grid";

.row-sm {
    margin-right: -#{(calc(#{$grid-gutter-width-sm} / 2))};
    margin-left: -#{(calc(#{$grid-gutter-width-sm} / 2))};

    > * {
        padding-right: calc(#{$grid-gutter-width-sm} / 2);
        padding-left: calc(#{$grid-gutter-width-sm} / 2);
    }
}
