@import "./../required";

@import "bootstrap/scss/progress";

.progress-bar-indeterminate {
    width: 100%;
    transform: scaleX(.5);
    transform-origin: -200%;
    animation: progress-bar-indeterminate 1s linear infinite;
}
@keyframes progress-bar-indeterminate {
    from { transform-origin: -200%; }
    to { transform-origin: 200%; }
}
