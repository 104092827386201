.animation-pulse {
    animation: pulse 1s linear infinite alternate;
    will-change: transform, opacity;
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .7;
        transform: scale(.9);
    }
}

.animation-highlight-shadow {
    animation: highlight-shadow .25s linear 0s 5 alternate;
}

@keyframes highlight-shadow {
    from {
        box-shadow: $box-shadow;
    }

    to {
        box-shadow: none;
    }
}

.animation-slide-up {
    animation: slide-up .15s linear;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}
