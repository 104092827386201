
@import "./../scss/required";

.the-notifications {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
}
