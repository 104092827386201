.border-after {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        flex: 1;
        height: $border-width;
        margin-left: $spacer;
        content: " ";
        background-color: $hr-border-color;
    }
}
