@use "sass:map";
@use "sass:color";

@import "bootstrap/scss/buttons";

// buttons
.btn-primary {
    font-weight: $font-weight-bold;
}

.btn-outline-primary {
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $primary;
        background-color: color.adjust($primary, $lightness: 62%);
    }
}

.btn-link {
    &.p-0,
    &.py-0 {
        vertical-align: inherit;
    }
}

.btn-outline-light {
    color: $gray-600;
    border: $border-width solid $gray-600;

    &:hover {
        color: $primary;
        background: color.adjust($primary, $lightness: 62%);
        border: $border-width solid $primary;
    }
}

.btn-none,
.btn.btn-none {
    background-color: transparent;
    border-color: transparent;
}

// TODO remove deprecated `.btn-block`
// https://getbootstrap.com/docs/5.0/migration/#buttons
.btn.btn-block {
    display: block;
    width: 100%;

    + .btn-block {
        margin-top: map.get($spacers, 2);
    }
}

// custom buttons
.btn-apps {
    display: inline-flex;
    align-items: center;
    font-size: $font-size-lg;
    color: $body-color;

    .icon {
        width: 32px;
        height: 32px;
        margin-right: map.get($spacers, 2);
    }
    &:hover {
        color: color.adjust($body-color, $lightness: -15%);
    }
}

.btn-favorites {
    width: 40px;
    height: 40px;
    color: $body-color;
    background-color: rgba($white, .6);
    border-radius: 50%;

    &:hover {
        background-color: $white;
    }
}

