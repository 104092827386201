@use "sass:map";

$color-muted:       $gray-600;
$label-font-size:   $font-size-xs;
$label-font-weight: $font-weight-bold;

.label {
    font-size: $label-font-size;
    font-weight: $label-font-weight;
}

.label-muted {
    color: $color-muted;
}

.label-danger {
    color: $danger;
}

.label-success {
    color: $success;
}

.label-warning {
    color: $warning;
}

.label-diamond {
    margin-left: 2px;

    &::before {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-right: calc(#{$spacer} / 2);
        content: "";
        transform: rotate(45deg);
    }
}

.label-diamond-muted {
    &::before {
        background-color: $color-muted;
    }
}

.label-diamond-danger {
    &::before {
        background-color: $danger;
    }
}

.label-diamond-success {
    &::before {
        background-color: $success;
    }
}

.label-diamond-warning {
    &::before {
        background-color: $warning;
    }
}

.label-notification {
    margin-bottom: 0;

    .icon {
        margin-right: map.get($spacers, 1);
        color: $gray-500;
    }

    .icon-muted {
        color: $color-muted;
    }

    .icon-danger {
        color: $danger;
    }

    .icon-success {
        color: $success;
    }

    .icon-warning {
        color: $warning;
    }
}
