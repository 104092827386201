@use "sass:math";

@import "bootstrap/scss/images";

// img responsive
.img-responsive {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;

    &::before {
        display: block;
        content: "";
    }

    img,
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
}
.img-responsive-1by1 {
    &::before {
        padding-top: 100%;
    }
}
.img-responsive-2by1 {
    &::before {
        padding-top: math.percentage(calc(1 / 2));
    }
}
