@use "sass:map";

@import "./../required";

.notification-gdpr {
    position: relative;
    z-index: $zindex-fixed;
    order: 5;
    padding: $spacer * .75 0;
    color: $secondary;
    background-color: $white;
    border-top: 1px solid $border-color;

    @include media-breakpoint-up(lg) {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
    }

    span {
        font-size: $small-font-size;
    }
}
