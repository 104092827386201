.tooltip {
    position: relative;
}

.tooltip::before,
.tooltip::after {
    position: absolute;
    z-index: $zindex-tooltip;
    display: none;
    pointer-events: none;
    opacity: 0;
}
.tooltip::before {
    width: 120px;
    padding: $tooltip-padding-y $tooltip-padding-x;
    line-height: $line-height-sm;
    color: $tooltip-color;
    text-align: center;
    white-space: normal;
    content: attr(data-tooltip);
    background-color: $tooltip-bg;
    @include font-size($tooltip-font-size);
    @include border-radius($tooltip-border-radius);
}
.tooltip::after {
    width: 0;
    height: 0;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.tooltip-top::before {
    bottom: 100%;
    left: 50%;
    margin-bottom: $tooltip-arrow-height;
    transform: translateX(-50%);
}
.tooltip-top::after {
    bottom: 100%;
    left: 50%;
    border-width: $tooltip-arrow-size calc($tooltip-arrow-width / 2) 0;
    border-top-color: $tooltip-bg;
    transform: translateX(-50%);
}

.tooltip:hover::before,
.tooltip:hover::after,
.tooltip-show::before,
.tooltip-show::after {
    display: block;
    opacity: 1;
    transition: $transition-fade;
}
