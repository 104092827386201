hr[data-text] {
    display: flex;
    align-items: center;
    justify-content: center;

    // overwrite bootstrap
    border-top: 0;
    opacity: 1;

    &::before {
        padding: 0 $spacer;
        font-size: $font-size-sm;
        content: attr(data-text);
        background: $white;
    }
}
