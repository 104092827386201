@import "bootstrap/scss/containers";

// Override bootstrap
.container-fluid {
    min-width: 320px;
    max-width: 1230px;
}

// Extend bootstrap
.container-full {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
}
.container-small {
    max-width: 840px;
}
