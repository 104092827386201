.cover-gradient-right,
.cover-gradient-bottom {
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        pointer-events: none;
        content: "";
    }
}

.cover-gradient-left {
    position: relative;

    &::before {
        position: absolute;
        pointer-events: none;
        content: "";
    }
}

.cover-gradient-right::after {
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    background: linear-gradient(to right, rgba($white, 0), $white 100%);
}

.cover-gradient-bottom::after {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(to bottom, rgba($white, 0), $white 100%);
}

.cover-gradient-left::before { /* stylelint-disable-line no-duplicate-selectors */
    top: 0;
    left: 0;
    z-index: 1;
    width: 25%;
    height: 100%;
    background: linear-gradient(to left, rgba($white, 0), $white 100%);
}
