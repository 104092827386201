@each $color, $value in $social-colors {
    .bg-#{$color} {
        background-color: $value;

        &,
        &:hover,
        &:focus {
            color: color-contrast($value, $min-contrast-ratio: 1.98);
        }

        &.btn:hover {
            background-color: shade-color($value, $btn-hover-bg-shade-amount);
        }
    }
}
