@import "./../required";

// structure app
html,
body,
.the-app,
.the-wrapper {
    display: flex;
    flex-direction: column;
}
html {
    min-height: 100%;
}
body,
.the-app,
.the-wrapper {
    flex: 1;
}

// .the-wrapper {  }

// .the-header { }
.the-content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding-top: $spacer;
    padding-bottom: $spacer;
    background-color: $gray-100;

    > .h-100:only-child {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: -$spacer;
        margin-bottom: -$spacer;

        > *:only-child {
            margin: auto;
        }
    }
}
// .the-footer {  }
