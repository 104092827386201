
@import "@/scss/main";
@import "@/scss/05-components/app";

.sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
