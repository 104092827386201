// text colors

.text-strong-success {
    strong {
        color: $success;
    }
}

.text-primary-2 {
    color: $primary-2;
}
