.scroll {
    display: block;
    width: 100%;
    overflow: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgba($black, .25) rgba(255, 255, 255, 0);
}

.scroll-x {
    overflow-x: auto;
    white-space: nowrap;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-hide-bar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.scroll-custom-bar {
    &::-webkit-scrollbar {
        width: 6px;
        appearance: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba($black, .25);
        border-radius: 4px;
        box-shadow: 0 0 1px rgba($white, .5);
    }
}
