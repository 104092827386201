
@import "./../scss/required";

.loader-container {
    z-index: $zindex-modal-backdrop;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($white, .75);

    &.loader-container-full-page {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}
