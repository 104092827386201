@import "./../required";

.icon {
    display: inline-flex;
    flex-shrink: 0; // side effect: if parent is flex, don't let this shrink
    align-items: center;
    justify-content: center;
    width: $font-size-base * $line-height-base;
    height: $font-size-base * $line-height-base;
    font-size: $font-size-base;
    vertical-align: middle;

    svg {
        width: 100%;
        height: 100%;

        use {
            pointer-events: none;
        }
    }

    // sizes
    &.icon-sm {
        width: $font-size-sm * $line-height-sm;
        height: $font-size-sm * $line-height-sm;
        font-size: $font-size-sm;
    }
    &.icon-lg {
        width: $font-size-lg * $line-height-lg;
        height: $font-size-lg * $line-height-lg;
        font-size: $font-size-lg;
    }
    &.icon-xl {
        width: $font-size-lg * $line-height-lg * 1.25;
        height: $font-size-lg * $line-height-lg * 1.25;
        font-size: $font-size-lg * 1.25;
    }

    // shapes
    &.icon-circle {
        background-color: $body-bg;
        border: $border-width solid $border-color;
        border-radius: 50%;

        svg {
            width: 75%;
            height: 75%;
        }
    }

    // notification
    &[data-notification] {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-size: $font-size-xs;
            line-height: 1;
            color: color-yiq($primary);
            content: attr(data-notification);
            background-color: $primary;
            border-radius: 50%;
            box-shadow: $box-shadow-sm;
            transform: translate(50%, -50%);
        }
    }

    // dot
    &[data-dot] {
        position: relative;

        &::after {
            position: absolute;
            top: -1px;
            right: -1px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8px;
            height: 8px;
            content: "";
            background-color: $primary-2;
            border-radius: 50%;
            box-shadow: $box-shadow-sm;
            transform: translate(50%, -50%);
        }
    }

    // favorites
    &.icon-favorites {
        svg {
            fill: $white;
            stroke: currentcolor;
            stroke-width: 1.5;
        }
    }
    .active > &.icon-favorites {
        svg {
            fill: $primary;
            stroke: $primary;
        }
    }
}
