@use "sass:map";

// product price
.price-rrp {
    font-size: $font-size-sm;

    .icon {
        margin-left: map.get($spacers, 1);
    }
}

.price-sth {
    font-size: $font-size-sm;
    color: $gray-600;

    .price-sth-value {
        text-decoration: line-through;
    }
}

// cart & orders summary
.summary-price {
    li {
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;

        > * {
            flex: 1 0 0;
            text-align: right;

            &:first-child {
                flex-grow: 2;
                margin-right: auto;
                text-align: left;
            }
        }

        &:last-child {
            font-weight: $font-weight-bold;
        }
    }
}
