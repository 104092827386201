@use "sass:map";

@import "bootstrap/scss/type";

.h1,
h1 {
    font-size: $h2-font-size;

    @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
    }
}

.list-inline-wrap {
    .list-inline-item {
        margin-bottom: $list-inline-padding;

        .icon {
            margin-right: map.get($spacers, 1);
            color: $gray-500;
        }
    }

    &.list-inline-s-3 {
        .list-inline-item {
            margin-bottom: map.get($spacers, 3);
        }
    }
}

.list-inline-s-3 {
    .list-inline-item:not(:last-child) {
        margin-right: map.get($spacers, 3);
    }
}
