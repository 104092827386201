@use "sass:map";
@use "sass:color";

@import "bootstrap/scss/badge";

@each $color, $value in $theme-colors {
    .badge-#{$color} {
        color: color-contrast($value);
        background-color: $value;
    }
}

.badge-lg {
    padding: map.get($spacers, 2) $spacer;
    font-size: $font-size-base;
}

.badge-warning {
    color: color.adjust($yellow, $lightness: -15%);
    background-color: color.adjust($yellow, $lightness: 40%);
}
