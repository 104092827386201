.divider-bottom {
    @include divider-bottom();
}

.divider-bottom-sm {
    @include media-breakpoint-up(sm) {
        @include divider-bottom();
    }
}

.divider-bottom-lg {
    @include media-breakpoint-up(lg) {
        @include divider-bottom();
    }
}
