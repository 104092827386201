@import "bootstrap/scss/transitions";

// slide up
.slide-up-enter-active {
    transition: $transition-base;
}
.slide-up-leave-active {
    transition: $transition-base;
}
.slide-up-enter,
.slide-up-leave-to {
    transform: translateY(100%);
}
