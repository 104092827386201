
@import "./../scss/05-components/progress";

.progress-page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    height: 4px;
    border-radius: 0;
    opacity: .6;
}
